<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="创建日期">
              <a-range-picker @change="onCreateDateChange" v-model="defaultDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="交易日">
              <a-range-picker @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="收款人开户行">
              <a-select
                v-model="queryParam['bank_id']"
                showSearch
                allowClear
                placeholder="请选择收款人开户行"
              >
                <a-select-option :value="item.id" v-for="item in bankOps" :key="item.id">
                  {{ item.bank }}/{{ item.bank_account }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="币种">
              <a-input v-model.trim="queryParam['%currency']" placeholder="根据币种搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款人名称">
              <a-input v-model.trim="queryParam['%pay_name']" placeholder="根据付款人名称搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="金额">
              <a-input v-model.trim="queryParam['amount']" placeholder="根据金额搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="银行流水号">
              <a-input v-model.trim="queryParam['%bank_serial_number']" placeholder="根据银行流水号搜索" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-upload :customRequest="handleImport" :showUploadList="false">
        <a-button type="primary">导入</a-button>
      </a-upload>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 'max-content' }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a style="color:red" @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible"
      :confirmLoading="confirmLoading"
      :width="900"
      :maskClosable="false"
      :title="modalFlag === 'create' ? '创建资金登记' : '编辑资金登记'"
      @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="6">
            <a-form-model-item label="银行流水号" prop="bank_serial_number">
              <a-input v-model.trim="form.bank_serial_number"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="交易日" prop="arrive_date">
              <a-date-picker v-model="form.arrive_date" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="付款人名称">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择付款人名称"
                style="width: 100%"
                :value="form.customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="交易货币">
              <a-input v-model.trim="form.currency" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="交易金额">
              <a-input v-model.trim="form.amount" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="交易附言">
              <a-input v-model.trim="form.remark" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="收款人账号">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择收款人账号"
                style="width: 100%"
                v-model="form.receive_account"
                @change="receiveAccountChange"
              >
                <a-select-option v-for="op in bankOps" :key="op.bank_account">{{ op.bank_account }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="发票号">
              <a-input v-model.trim="form.invoice_num"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="收款人开户行">
              <a-input v-model.trim="form.receive_bank" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import { getSystemInfoOption } from '@/api/system'
import { customizeAssign, getFirstLetter } from '@/utils/util'
import {
getFmsMoneyRegisterPage,
saveFmsMetaInfo,
deleteFmsMetaInfo,
cashImport,
getFmsMetaOption
} from '@/api/fms'
import { Empty } from 'ant-design-vue'
import debounce from 'lodash/debounce'
import { getCustomer } from '@/api/common'
export default {
  components: {
    STable,
    Empty
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      agencyOps: [],
      customerInfo: undefined,
      fetching: false,
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create',
      queryParam: {},
      defaultDate: [moment().startOf('month'), moment().endOf('month')],
      userOps: [],
      // businessTypeOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '银行流水号',
          dataIndex: 'bank_serial_number'
        },
        {
          title: '交易日',
          dataIndex: 'arrive_date',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '付款人名称',
          dataIndex: 'pay_name'
        },
        {
          title: '交易货币',
          dataIndex: 'currency'
        },
        {
          title: '交易金额',
          dataIndex: 'amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '交易附言',
          dataIndex: 'remark'
        },
        {
          title: '收款人开户行',
          dataIndex: 'receive_bank'
        },
        {
          title: '收款人账号',
          dataIndex: 'receive_account'
        },
        {
          title: '创建日期',
          dataIndex: 'created_at',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD HH:MM:SS')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      form: {
        id: '',
        bank_serial_number: '',
        arrive_date: null,
        currency: '',
        receive_account: '',
        amount: '',
        remark: '',
        receive_bank: '',
        pay_name: '',
        invoice_num: '',
        customer_id: '',
        customerInfo: undefined
      },
      rules: {
        bank_serial_number: [{ required: true, message: '请填写银行流水号', trigger: 'blur' }],
        arrive_date: [{ required: true, message: '请填写到账日期', trigger: 'change' }]
        // register: [{ required: true, message: '请选择登记人', trigger: 'blur' }]
      },
      selectedRowKeys: [],
      currentData: null,
      bankOps: [],
      loadData: parameter => {
        return getFmsMoneyRegisterPage('finance', Object.assign(parameter, this.queryParam)).then(res => {
          return {
            list: res.records,
            total: res.total
          }
        })
      },
      advanceOps: [
        { value: 1, name: `否` },
        { value: 2, name: `是` }
      ]
    }
  },
  created() {
    getSystemInfoOption('user').then(v => {
      this.userOps = v
    })
    // getFmsMetaOption('fms_business_type').then(v => {
    //   this.businessTypeOps = v
    // })
    getFmsMetaOption('fms_bank').then(v => {
      this.bankOps = v
    })
    this.queryParam['@created_at'] = [
      moment()
        .startOf('month')
        .valueOf(),
      moment()
        .endOf('month')
        .valueOf()
    ]
  },
  methods: {
    moment,
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agencyOps = [];
      this.fetching = true;
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agencyOps = res
        this.fetching = false;
      })
    },
    getDepartmentCustomerEdit(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.agencyOps = [];
      this.fetching = true;
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.agencyOps = res
        if (res && res.length > 0) {
          this.form.customerInfo = {
            'key': res[0].value, 'label': res[0].name
          }
        }
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.form.customerInfo = value
        this.form.pay_name = value.label
        this.form.customer_id = value.key
        this.agencyOps = []
        this.fetching = false
      } else {
        this.form.customerInfo = value
        this.form.pay_name = ''
        this.form.customer_id = ''
        this.agencyOps = []
        this.fetching = false
      }
    },
    handleCreate() {
      this.modalFlag = 'create'
      this.handleFormReset()
      this.formVisible = true
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.handleFormReset()
      customizeAssign(this.form, {
        ...record,
        arrive_date: record['arrive_date'] ? moment(record['arrive_date'], 'YYYY/MM/DD') : null
      })
      this.getDepartmentCustomerEdit(record.pay_name)
      this.formVisible = true
    },
    handleFormReset() {
      this.form = {
        id: '',
        arrive_date: null,
        // business_type: '',
        // pay_bank: '',
        // pay_account: '',
        currency: '',
        receive_account: '',
        amount: '',
        // target: '',
        remark: '',
        receive_bank: '',
        // register: null,
        pay_name: '',
        invoice_num: '',
        customer_id: '',
        customerInfo: undefined,
        bank_serial_number: ''
      }
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteFmsMetaInfo('finance', _ids).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = []
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onCreateDateChange(date, dateString) {
      if (date[0]) {
        const _date = [date[0].startOf('day').valueOf(), date[1].endOf('day').valueOf()]
        this.queryParam['@created_at'] = _date
      } else {
        this.queryParam['@created_at'] = []
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        const _date = [date[0].startOf('day').valueOf(), date[1].endOf('day').valueOf()]
        this.queryParam['@arrive_date'] = _date
      } else {
        this.queryParam['@arrive_date'] = []
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (typeof this.form.customerInfo !== 'undefined') {
            this.form.pay_name = this.form.customerInfo.label
            this.form.customer_id = this.form.customerInfo.key
          }
          saveFmsMetaInfo('finance', {
            ...this.form,
            arrive_date: this.form.arrive_date ? moment(this.form.arrive_date).valueOf() : null
          })
            .then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              this.$refs.table.refresh(this.modalFlag === 'create')
            })
            .catch(_ => {
              this.confirmLoading = false
            })
        }
      })
    },
    receiveAccountChange(val, option) {
      this.bankOps.forEach(data => {
        if (data.bank_account === val) {
          this.form.receive_bank = data.bank
        }
      })
    },
    handleImport(obj) {
      var formData = new FormData()
      formData.append('uploadFile', obj.file)
      cashImport(formData).then(v => {
        obj.onSuccess()
        this.$notification['success']({
          message: '提示',
          description: '导入成功'
        })
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>
